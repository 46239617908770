'use strict';
import {localize} from "./localization.mjs";
import 'bootstrap-datepicker' // popup .datepicker()
import $ from 'jquery';
import validate from 'jquery-validation'

var Login = function () {

    /** Absenden von Logindaten */
    var loginsubmit = function loginsubmit() {

        let postdata = $('.login-form').serialize();
        let getparams = '';

        mApp.block('.m-login__wrapper', {});

        if ($('.login-form').data('query').length > 0) {
            getparams = "&" + $('.login-form').data('query');
        }

        // as captcha inputs might be undefined, we need to make sure, we provide empty values at least
        let captcha_element = $('.login-form input[name=captcha]');
        if (captcha_element.length) {
            getparams += "&captcha=" + captcha_element.val();
            getparams += "&_captcha_ns=" + $('.login-form input[name=_captcha_ns]').val();
        } else {
            // serve as empty
            getparams += "&captcha=&_captcha_ns=";
        }

        let textSubmit1 = "Abschicken";
        let textSubmit2 = "Einloggen";
        localize('LOGIN').then(function (__) {
            textSubmit1 = __("Abschicken");
            textSubmit2 = __("Einloggen");
        });

        $.ajax({
            type: "POST",
            url: EVEWA_URL + "/evewa4/evewa4ajax.php?file=ev3login" + getparams,
            data: postdata,
            success: function success(response_data) {

                let data = JSON.parse(response_data);

                if (data.success === "2FA_auth_trigger") {

                    // as in standard login
                    $('#alert-box').addClass('alert-success').removeClass('alert-error').hide();
                    $('.login-form').validate().reset();

                    // clear ebrowse search cache
                    if (sessionStorage) {
                        sessionStorage.clear();
                    }

                    // clear local (browser) storage , e.g. for jstree (erweiterte selektion)
                    if (localStorage) {
                        localStorage.clear();
                    }

                    mApp.unblock('.m-login__wrapper'); // unblock the section

                    // go to the 2FA login form
                    $('#secsignid-loginform #login-secsignid').val(data.secsignid);
                    $('#secsignid-loginform').submit();
                    return;
                }

                if (data.success) {
                    // prevent another submit trigger
                    $('button[type="submit"]').hide();

                    $('#alert-box').addClass('alert-success').removeClass('alert-error').hide();
                    $('.login-form').validate().reset();

                    // clear ebrowse search cache
                    if (sessionStorage) {
                        sessionStorage.clear();
                    }

                    // clear local (browser) storage , e.g. for jstree (erweiterte selektion)
                    if (localStorage) {
                        localStorage.clear();
                    }

                    // redirect to generated URL after successful login
                    window.location.href = data.url;
                } else {
                    // unsuccessful login
                    // unblock UI for upcoming interaction
                    mApp.unblock('.m-login__wrapper');
                    $('#alert-box')
                        .removeClass('alert-success')
                        .addClass('alert-error')
                        .html(data.message)
                        .show();

                    // make sure the captcha form element is empty, so new on can be inserted, if necessary
                    $('#captcha_group').empty();

                    if (data.captcha) {
                        // add captcha form element for security check - you need to pass the test first
                        $('#captcha_group').append(data.captcha_code);

                        $('#username_password').hide();
                        $('button.login-submit[type="submit"]').text(textSubmit1); // default submit text for Captcha submit+test
                        $('.login-form input[name="captcha"]').val("").focus();
                    } else {
                        // not successful login, but captcha is not necessary
                        // show the password input and enable for filling
                        $('#username_password').show();
                        $('button.login-submit[type="submit"]').text(textSubmit2); // default submit text for regular submit
                        $('.login-form input[name="password"]').val("").focus();
                    }

                    // unsuccessful login with bad CSRF token, we need to reload the page
                    if (data.reload) {
                        setTimeout(function () {
                            window.location.reload(true);
                        }, 5000);
                    } else {
                        // update the CSRF token, so next form can be submitted
                        $('input[name="ev4csrf"]').val(data.csrf_token);
                    }
                }
            }
        });
    };

    /**
     * Absenden der Erstanmeldedaten
     */
    var erstanmeldungsubmit = function erstanmeldungsubmit(event) {
        event.preventDefault();
        var postdata = $('.erstanmeldung-form').serialize();
        var getparams = '';

        if ($('.erstanmeldung-form').data('query').length > 0) {
            getparams = "&" + $('.erstanmeldung-form').data('query');
        }

        $.ajax({
            type: "POST",
            url: EVEWA_URL + "/evewa4/evewa4ajax.php?file=ev3login" + getparams,
            data: postdata,
            dataType: "json",
            success: function success(data) {
                if (data.success) {
                    // clear ebrowse search cache
                    if (sessionStorage) {
                        sessionStorage.clear();
                    }

                    // clear local (browser) storage , e.g. for jstree (erweiterte selektion)
                    if (localStorage) {
                        localStorage.clear();
                    }

                    window.location.href = data.url;
                    $('.erstanmeldung-form').validate().reset();
                    $('#alert-box').hide();
                } else {
                    $('#alert-box')
                        .removeClass('alert-success')
                        .addClass('alert-error')
                        .html(data.message)
                        .show();
                }
            }
        });
    };

    /**
     * Absenden des Social-Login-Formulars.
     * In diesem wurde die MITGLIEDNR und das GEB_DAT angegeben.
     *
     * @returns {undefined}
     */
    var social_loginsubmit = function social_loginsubmit() {
        var postdata = $('.social-login-form').serialize();

        // Hier kann kein Ajax-Request stattfinden!
        location.href = EVEWA_URL + "/evewa4ajax.php?file=ev3login&" + postdata;
    };

    /**
     * Öffnet das Popup-Fenster mit der Social-Login-Seite.
     *
     * @param string provider Facebook, Google, etc.
     * @param string target Ziel-URL, optional
     * @returns {undefined}
     */
    var openSocialLoginWindow = function openSocialLoginWindow(provider, target) {
        if (target === '' || target === undefined) {
            target = EVEWA_URL + '/evewa4ajax.php?file=ev3login&provider=' + provider;
        }

        var width = 500;
        var height = document.body.scrollHeight + 50;
        window.open(target, provider + ' Login', 'width=' + width + ',height=' + height + ',toolbar=0,menubar=0,location=0,status=1,scrollbars=1,resizable=1,left=0,top=0');
    };

    /** Absenden der E-Mailadresse bei PW-Verlust */
    var forgetsubmit = function forgetsubmit() {
        var username = $('.forget-form input[name=username]').val();
        var ev4csrf = $('.forget-form input[name=ev4csrf]').val();

        // pre-set captcha values
        let captcha = "";
        let captcha_ns = "";

        let captcha_element = $('.forget-form input[name=captcha]');
        if (captcha_element) {
            captcha = captcha_element.val();
            captcha_ns = $('.forget-form input[name=_captcha_ns]').val();
        }

        mApp.block('.m-login__wrapper', {});

        $.ajax({
            type: "POST",
            url: EVEWA_URL + "/evewa4/evewa4ajax.php?file=ev3forgetpw",
            data: {
                username: username,
                captcha: captcha,
                ev4csrf: ev4csrf,
                link: EVEWA_URL,
                _captcha_ns: captcha_ns
            },
            dataType: "json",
            success: function success(data) {
                if (data.error) {
                    let error = getMessageEv4('danger', 'alert-box-pw', false, data.msg);

                    $('#forgetpw-alert').html(error);
                    $('.forget-form .alert-error', $('.forget-form')).show();
                    $('.forget-form input[name=email]').val('');

                    // make sure the captcha form element is empty, so new on can be inserted, if necessary
                    $('.forget-form #captcha_group').empty();

                    if (data.captcha) {
                        // add captcha form element for security check - you need to pass the test first
                        $('.forget-form #captcha_group').append(data.captcha_code);
                        $('.forget-form input[name="captcha"]').val("").focus();
                    }

                    // unsuccessful login with bad CSRF token, we need to reload the page
                    if (data.reload) {
                        setTimeout(function () {
                            window.location.reload(true);
                        }, 5000);
                    } else {
                        // update the CSRF token, so next form can be submitted
                        $('input[name="ev4csrf"]').val(data.csrf_token);
                    }

                    mApp.unblock('.m-login__wrapper');
                } else {
                    let succ = getMessage('success', 'alert-box-pw', false, data.msg);

                    $('#forgetpw-alert').html(succ).addClass('m--margin-top-25');
                    $('.alert-success', $('.forget-form')).show();
                    $('.forget-form input[name=email]').hide();

                    $('.forget-form').find('.icon-envelope').remove();
                    $('.forget-form').find('p').hide();

                    // show only "Back" button
                    $('#m_login_forget_password_submit').hide();
                    $('.forget-form .form-group').hide();

                    mApp.unblock('.m-login__wrapper');
                }
            }
        });
    };

    /** Absenden des Registrierungsformulars */
    var registersubmit = function registersubmit() {
        mApp.block('.m-login__wrapper', {});

        $.ajax({
            type: "POST",
            url: EVEWA_URL + "/evewa4/evewa4ajax.php?file=ev3reguser",
            data: $('.register-form').serialize() + '&' + $.param({'username': $('#username').val()}),
            dataType: "json",
            success: function success(data) {
                if (data.error) {
                    // unsuccessful login
                    $('#register-alert')
                        .removeClass('alert-success, alert-error')
                        .addClass('alert alert-danger alert-error')
                        .html(data.msg)
                        .show();

                    // make sure the captcha form element is empty, so new on can be inserted, if necessary
                    $('.register-form #captcha_group').empty();

                    if (data.captcha) {
                        // add captcha form element for security check - you need to pass the test first
                        $('.register-form #captcha_group').append(data.captcha_code);
                        $('.register-form input[name="captcha"]').val("").focus();
                    }

                    // unsuccessful login with bad CSRF token, we need to reload the page
                    if (data.reload) {
                        setTimeout(function () {
                            window.location.reload(true);
                        }, 5000);
                    } else {
                        // update the CSRF token, so next form can be submitted
                        $('input[name="ev4csrf"]').val(data.csrf_token);
                    }

                    // unblock UI for upcoming interaction
                    mApp.unblock('.m-login__wrapper');
                } else {
                    let succ = getMessage('success', 'alert-box-reg', false, data.msg);

                    $('#register-alert').replaceWith(succ);
                    $('.alert-success', $('.register-form')).show();
                    $('.register-form .control-group').hide();
                    $('#register-submit-btn').hide();

                    mApp.unblock('.m-login__wrapper');
                }
            }
        });
    };

    /** Absenden des Registrierungsformulars */
    var socialregistersubmit = function socialregistersubmit() {
        mApp.block('.m-login__wrapper', {});

        $.ajax({
            type: "POST",
            url: EVEWA_URL + "/evewa4/evewa4ajax.php?file=ev3reguser",
            data: $('.social-register-form').serialize(),
            dataType: "json",
            success: function success(data) {
                if (data.error) {
                    var error = getMessage('error', 'alert-box-reg', true, data.msg);

                    $('#register-alert').html(error);
                    $('.alert-error', $('.social-register-form')).show();

                    mApp.unblock('.m-login__wrapper');
                } else {
                    eval(data.code);
                }
            }
        });
    };

    /** First login */
    var firstLoginSubmit = function firstLoginSubmit() {
        mApp.block('.m-login__wrapper', {});

        var firstLoginForm = $('.first-login-form');

        $.ajax({
            type: "POST",
            url: EVEWA_URL + "/evewa4/evewa4ajax.php?file=ev4firstlogin",
            data: firstLoginForm.serialize(),
            dataType: "json",
            success: function success(data) {
                if (data.error) {
                    // unsuccessful try
                    $('#first-login-alert')
                        .removeClass('alert-success, alert-error')
                        .addClass('alert alert-danger alert-error')
                        .html(data.msg)
                        .show();

                    // unsuccessful login with bad CSRF token, we need to reload the page
                    if (data.reload) {
                        setTimeout(function () {
                            window.location.reload(true);
                        }, 5000);
                    } else {
                        // update the CSRF token, so next form can be submitted
                        $('input[name="ev4csrf"]').val(data.csrf_token);
                    }

                    mApp.unblock('.m-login__wrapper');
                } else {
                    let succ = getMessage('success', 'alert-box-pw', false, data.msg);

                    $('#first-login-alert').html(succ);
                    $('.alert-success', firstLoginForm).show();
                    $('.first-login-form .control-group').hide();
                    $('#first-login-submit-btn').hide();

                    mApp.unblock('.m-login__wrapper');
                }
            }
        });
    };

    /** Submit the form password re-setting */
    var resetPasswordSubmit = function resetPasswordSubmit() {
        mApp.block('.m-login__wrapper', {});

        var resetPasswordForm = $('.reset-password-form');

        $.ajax({
            type: "POST",
            url: EVEWA_URL + "/evewa4/evewa4ajax.php?file=ev3resetpw",
            data: resetPasswordForm.serialize(),
            dataType: "json",
            success: function success(data) {
                if (data.error) {
                    // unsuccessful try
                    $('#reset-password-alert')
                        .removeClass('alert-success, alert-error')
                        .addClass('alert alert-danger alert-error')
                        .html(data.msg)
                        .show();

                    // unsuccessful login with bad CSRF token, we need to reload the page
                    if (data.reload) {
                        setTimeout(function () {
                            window.location.reload(true);
                        }, 5000);
                    } else {
                        // update the CSRF token, so next form can be submitted
                        $('input[name="ev4csrf"]').val(data.csrf_token);
                    }

                    mApp.unblock('.m-login__wrapper');
                } else {
                    let succ = getMessage('success', 'alert-box-pw', false, data.msg);

                    $('#reset-password-alert').removeClass('alert-danger alert-error').html(succ);
                    $('.alert-success', resetPasswordForm).show();
                    $('.reset-password-form .control-group').hide();
                    $('#reset-password-submit-btn').hide();

                    setTimeout(function () {
                        window.location.href = EVEWA_URL + '/';
                        mApp.unblock('.m-login__wrapper');
                    }, 2500);
                }
            }
        });
    };

    /** Erzeugt Erfolgs-/Fehlermeldung */
    var getMessage = function getMessage(alertclass, id, closebtn, msg) {
        var rmsg = '<div class="alert alert-' + alertclass + ' hide" id="' + id + '">';
        if (closebtn) {
            rmsg += '<button class="close" data-dismiss="alert"></button>';
        }
        rmsg += '<span>' + msg + '</span></div>';
        return rmsg;
    };

    /** Erzeugt Erfolgs-/Fehlermeldung */
    var getMessageEv4 = function getMessageEv4(alertclass, id, closebtn, msg) {
        var rmsg = '<div class="alert alert-' + alertclass + '" id="' + id + '">';
        if (closebtn) {
            rmsg += '<button class="close" data-dismiss="alert"></button>';
        }
        rmsg += '<span>' + msg + '</span></div>';
        return rmsg;
    };

    return {
        init: function init() {

            // first, enable the login button
            // by default it is disabled, as clicking on it (before init() had run) causes the page reload (default form submit to current URL)
            $('form.login-form button[type="submit"]').removeAttr("disabled");

            // show password
            $("#login-show-password").on('click', function (e) {
                let input = $("#login-password-input");
                if (input[0].type === "password") {
                    input[0].type = "text";
                    $("#login-show-password  i").removeClass("fa-eye");
                    $("#login-show-password  i").addClass("fa-eye-slash");
                    $("#login-show-password").attr("data-original-title","Passwort verbergen");
                } else {
                    input[0].type = "password";
                    $("#login-show-password i").removeClass("fa-eye-slash");
                    $("#login-show-password i").addClass("fa-eye");
                    $("#login-show-password").attr("data-original-title","Passwort anzeigen");
                }
            });
            
            // @roc: localize() wrapper was replaced, as it was blocking the login functionality,
            // until the AJAX call with translations was returned; localize('LOGIN').then(function (__) {
            // clicking on LOGIN button then triggered page reload, instead of login fnc (bug).

            // if rendering of reset password was triggered - auto-open it, instead of default login form
            if ($('.reset-password-form').length) {
                $('.login-form').hide();
                $('.reset-password-form').show();
            } else if ($('.first-login-form').length) {
                $('.login-form').hide();
                $('.first-login-form').show();
            }

            // if ?forgotten-password is present in the URL - switch to the PWD Forget form automatically.
            let forgottenPwdTrigger = 'forgotten-password';
            let forgottenPwdTriggerValue = decodeURI((RegExp(forgottenPwdTrigger).exec(location.search)||[,null])[1]);
            if (forgottenPwdTriggerValue !== 'null') {
                $('.login-form').hide();

                // block for waiting for captcha element
                mApp.block('.m-login__wrapper', {});

                $.ajax({
                    type: "POST",
                    url: EVEWA_URL + "/evewa4/evewa4ajax.php?file=ev3login&generate_captcha",
                    success: function success(response_data) {
                        let data = JSON.parse(response_data);

                        // add captcha form element for security check, all the time when using forget PWD function
                        $('.forget-form #captcha_group').empty().append(data.captcha_code);
                        $('.forget-form input[name="captcha"]').val("").focus();

                        // unblock UI for upcoming interaction
                        mApp.unblock('.m-login__wrapper');

                        jQuery('.forget-form').show();
                    }
                });

                // FYI: optional: add / hide some page element and so on here.
            }

            $('input[name=username]').focus();

            $('.date').datepicker({
                autoclose: true,
                todayHighlight: true,
                format: "dd.mm.yyyy",
                language: "de-DE"
            });

            // Register: form button clicks
            $('#back-btn').click(function () {
                $('.forget-form #captcha_group').empty();
                $('.forget-form').hide();
                $('.login-form').show();
            });

            $('#register-btn').click(function () {
                $('#refreshcaptcha img').hide();
                $('#refreshcaptcha').trigger('click');

                // hide both: standard form / 2FA form (to be sure)
                $('.login-form').hide();
                $('#secsignid-login').hide();
                // hide forgot password form
                $('.forget-form').hide();

                $('.register-form').show();
            });

            $('#register-back-btn').click(function () {
                $('.register-form').hide();

                // switch: standard form / 2FA form
                if ($('.login-form').length) {
                    $('.login-form').show();
                }
                else {
                    $('#secsignid-login').show();
                }
            });

            $('#reset-password-back-btn').click(function () {
                $('.login-form').show();
                $('.reset-password-form').hide();
            });

            $('#first-login-back-btn').click(function () {
                $('.login-form').show();
                $('.first-login-form').hide();
            });

            // 2FA / Standard: login forms switch
            $('.m-login__signin').on('click keyup', '#login-2fa-switch', function(event) {
                event.preventDefault();
                $('.login-form').hide();
                $('#secsignid-login').fadeIn(250);
                return false;
            });

            $('.m-login__signin').on('click keyup', '#login-standard-switch', function (event) {
                event.preventDefault();
                $('#secsignid-login').hide();
                $('.login-form').fadeIn(250);
                return false;
            });

            $('#forget-password').click(function () {

                // block for waiting for captcha element
                mApp.block('.m-login__wrapper', {});

                $.ajax({
                    type: "POST",
                    url: EVEWA_URL + "/evewa4/evewa4ajax.php?file=ev3login&generate_captcha",
                    success: function success(response_data) {
                        let data = JSON.parse(response_data);

                        // add captcha form element for security check, all the time when using forget PWD function
                        $('.forget-form #captcha_group').empty().append(data.captcha_code);
                        $('.forget-form input[name="captcha"]').val("").focus();

                        // unblock UI for upcoming interaction
                        mApp.unblock('.m-login__wrapper');

                        jQuery('.login-form').hide();
                        jQuery('.forget-form').show();
                    }
                });
            });

            $('#back-btn-social').click(function () {
                window.location.href = EVEWA_URL + '/verwaltung';
            });

            $('.social-login').click(function () {
                let provider = $(this).data('provider');
                let target = $(this).data('target');

                openSocialLoginWindow(provider, target);
            });

            $('#erstanmeldung-submit-btn').on('click', function (event) {
                if ($('.erstanmeldung-form').validate().form()) {
                    erstanmeldungsubmit(event);
                }
                return false;
            });

            // Register: keypress on form inputs
            $('.login-form input').keypress(function (e) {
                if (e.which == 13) {
                    if ($('.login-form').validate().form()) {
                        loginsubmit();
                    }
                    return false;
                }
            });

            $('.forget-form input').keypress(function (e) {
                if (e.which == 13) {
                    if ($('.forget-form').validate().form()) {
                        forgetsubmit();
                    }
                    return false;
                }
            });

            $('.social-login-form input').keypress(function (e) {
                if (e.which == 13) {
                    if ($('.social-login-form').validate().form()) {
                        social_loginsubmit();
                    }
                    return false;
                }
            });

            $('.register-form input').keypress(function (e) {
                if (e.which == 13) {
                    if ($('.register-form').validate().form()) {
                        registersubmit();
                    }
                    return false;
                }
            });

            $('.reset-password-form input').keypress(function (e) {
                if (e.which == 13) {
                    if ($('.reset-password-form').validate().form()) {
                        resetPasswordSubmit();
                    }
                    return false;
                }
            });

            let textUsernameRequired = "Bitte geben Sie Ihren Usernamen ein";
            let textUsernameRequiredAlternative = "Username ist erforderlich.";
            let textPasswordRequired = "Bitte geben Sie Ihr Kennwort ein";
            let textPasswordRequiredAlternative = "Bitte geben Sie ein Kennwort an";
            let textPasswordRepeat = "Bitte wiederholen Sie Ihr Kennwort";
            let textPasswordNotEqual = "Wiederholung des Kennworts nicht richtig";
            let textEmailRequired = "Bitte geben Sie eine E-Mail-Adresse an";
            let textEmailInvalid = "Bitte geben Sie eine korrekte Emailadresse an";
            let textCaptchaInvalid = "Der Sicherheitscode ist nicht korrekt!";
            let textBirthdayRequired = "Bitte geben Sie Ihr Geburtsdatum an";
            let textValidationFailed = 'Validierung konnte nicht durchgef&uuml;hrt werden.';
            let textDateFormat = "Bitte geben Sie das Datum im Format TT.MM.JJJJ an.";
            let textMemberRequired = "Mitgliedsnummer ist erforderlich.";

            localize('LOGIN').then(function (__) {
                textUsernameRequired = __("Bitte geben Sie Ihren Usernamen ein");
                textUsernameRequiredAlternative = __("Username ist erforderlich.");
                textPasswordRequired = __("Bitte geben Sie Ihr Kennwort ein");
                textPasswordRequiredAlternative = __("Bitte geben Sie ein Kennwort an");
                textPasswordRepeat = __("Bitte wiederholen Sie Ihr Kennwort");
                textPasswordNotEqual = __("Wiederholung des Kennworts nicht richtig");
                textEmailRequired = __("Bitte geben Sie eine Emailadresse an");
                textEmailInvalid = __("Bitte geben Sie eine korrekte Emailadresse an");
                textCaptchaInvalid = __("Der Sicherheitscode ist nicht korrekt!");
                textBirthdayRequired = __("Bitte geben Sie Ihr Geburtsdatum an");
                textValidationFailed = __('Validierung konnte nicht durchgef&uuml;hrt werden.');
                textDateFormat = __("Bitte geben Sie das Datum im Format TT.MM.JJJJ an.");
                textMemberRequired = __("Mitgliedsnummer ist erforderlich.");
            });

            // Register: form validation (with submits)
            $('.login-form').validate({
                errorElement: 'label', //default input error message container
                errorClass: 'help-inline', // default input error message class
                rules: {
                    username: {
                        required: true
                    },
                    password: {
                        required: true
                    },
                    remember: {
                        required: false
                    }
                },
                messages: {
                    username: {
                        required: textUsernameRequired
                    },
                    password: {
                        required: textPasswordRequired
                    }
                },
                invalidHandler: function invalidHandler(event, validator) {
                    // display error alert on form submit
                    $('.alert-error', $('.login-form')).show();
                },
                highlight: function highlight(element) {
                    // hightlight error inputs
                    $(element).closest('.control-group').addClass('error'); // set error class to the control group
                },
                success: function success(label) {
                    label.closest('.control-group').removeClass('error');
                    label.remove();
                },
                submitHandler: function submitHandler(form) {
                    loginsubmit();
                }
            });

            $('.erstanmeldung-form').validate({
                errorElement: 'label', //default input error message container
                errorClass: 'help-inline', // default input error message class
                invalidHandler: function invalidHandler(event, validator) {
                    //display error alert on form submit
                    $('.alert-error', $('.erstanmeldung-form')).show();
                },
                highlight: function highlight(element) {
                    // hightlight error inputs
                    $(element).closest('.control-group').addClass('error'); // set error class to the control group
                },
                success: function success(label) {
                    label.closest('.control-group').removeClass('error');
                    label.remove();
                },
                submitHandler: function submitHandler(form) {
                    erstanmeldungsubmit();
                }
            });

            $('.forget-form').validate({
                errorElement: 'label', // default input error message container
                errorClass: 'help-inline', // default input error message class
                rules: {
                    username: {
                        required: true
                    }
                },
                messages: {
                    username: {
                        required: textUsernameRequiredAlternative
                    }
                },
                invalidHandler: function invalidHandler(event, validator) { // display error alert on form submit

                },
                highlight: function highlight(element) {
                    // hightlight error inputs
                    $(element).closest('.control-group').addClass('error'); // set error class to the control group
                },
                success: function success(label) {
                    label.closest('.control-group').removeClass('error');
                    label.remove();
                },
                submitHandler: function submitHandler(form) {
                    forgetsubmit();
                }
            });

            $('.social-login-form').validate({
                errorElement: 'label', // default input error message container
                errorClass: 'help-inline', // default input error message class
                ignore: "",
                rules: {
                    mitgliednr: {
                        required: true
                    }
                },
                messages: {
                    mitgliednr: {
                        required: textMemberRequired
                    }
                },
                invalidHandler: function invalidHandler(event, validator) { // display error alert on form submit

                },
                highlight: function highlight(element) {
                    // hightlight error inputs
                    $(element).closest('.control-group').addClass('error'); // set error class to the control group
                },
                success: function success(label) {
                    label.closest('.control-group').removeClass('error');
                    label.remove();
                },
                submitHandler: function submitHandler(form) {
                    social_loginsubmit();
                }
            });

            $('.register-form').validate({
                errorElement: 'label', // default input error message container
                errorClass: 'help-inline', // default input error message class
                ignore: "",
                onkeyup: false,
                rules: {
                    password: {
                        required: true,
                        remote: {
                            type: 'post',
                            global: false,
                            url: "evewa4ajax.php",
                            data: {
                                file: 'checkPasswordAjax.php',
                                password: function password() {
                                    return $('#register_password').val();
                                },
                                username: function username() {
                                    return $('#username').val();
                                }
                            },
                            complete: function complete(returnedData) {
                                handleRemoteValidation('.register-form', 'password', returnedData);
                            }
                        }
                    },
                    email: {
                        required: true,
                        email: true,
                        remote: {
                            type: 'post',
                            global: false,
                            url: "evewa4ajax.php",
                            data: {
                                file: 'checkEmailAjax.php',
                                email: function email() {
                                    return $('.register-form input[name="email"]').val();
                                }
                            },
                            complete: function complete(returnedData) {
                                handleRemoteValidation('.register-form', 'email', returnedData);
                            }
                        }
                    }
                },
                messages: {
                    password: {
                        required: textPasswordRequiredAlternative
                    },
                    email: {
                        required: textEmailRequired,
                        email: textEmailInvalid
                    },
                    captcha: {
                        remote: textCaptchaInvalid
                    }
                },
                focusInvalid: false,
                invalidHandler: function invalidHandler(event, validator) { // display error alert on form submit
                    // display error alert on form submit
                    $('.alert-error, .register-form').show();
                },
                highlight: function highlight(element) {
                    // hightlight error inputs
                    $(element).closest('.control-group').addClass('error'); // set error class to the control group
                },
                errorPlacement: function (error, element) {
                    if (element.attr("name") === "tnc") {
                        // insert checkbox errors after the container
                        error.addClass('error').insertAfter($('.checkbox-error-placement'));
                    } else {
                        error.insertAfter(element); // default error placement.
                    }
                },
                success: function success(label) {
                    label.closest('.control-group').removeClass('error');
                    label.remove();
                },
                submitHandler: function submitHandler(form) {
                    registersubmit();
                },
            });

            $('.first-login-form').validate({
                errorElement: 'label', //default input error message container
                errorClass: 'help-inline', // default input error message class
                ignore: "",
                rules: {
                    password: {
                        required: true,
                        remote: {
                            type: 'post',
                            global: false,
                            url: "evewa4ajax.php",
                            data: {
                                file: 'checkPasswordAjax.php',
                                password: function password() {
                                    return $('#password').val();
                                },
                                username: function username() {
                                    return $('#username').val();
                                }
                            },
                            complete: function complete(returnedData) {
                                handleRemoteValidation('.first-login-form', 'password', returnedData);
                            }
                        }
                    },
                    rpassword: {
                        equalTo: "#password"
                    }
                },
                messages: { // custom messages for radio buttons and checkboxes
                    password: {
                        required: textPasswordRequiredAlternative
                    },
                    rpassword: {
                        required: textPasswordRepeat,
                        equalTo: textPasswordNotEqual
                    }
                },
                invalidHandler: function invalidHandler(event, validator) {//display error alert on form submit
                    // display error alert on form submit
                    $('.alert-error, .reset-password-form').show();
                },
                highlight: function highlight(element) {
                    // hightlight error inputs
                    $(element).closest('.control-group').addClass('error'); // set error class to the control group
                },
                success: function success(label) {
                    label.closest('.control-group').removeClass('error');
                    label.remove();
                },
                submitHandler: function submitHandler() {
                    firstLoginSubmit();
                }
            });

            $('.reset-password-form').validate({
                errorElement: 'label', //default input error message container
                errorClass: 'help-inline', // default input error message class
                ignore: "",
                rules: {
                    password: {
                        required: true,
                        remote: {
                            type: 'post',
                            global: false,
                            url: "evewa4ajax.php",
                            data: {
                                file: 'checkPasswordAjax.php',
                                password: function password() {
                                    return $('#password').val();
                                },
                                username: function username() {
                                    return $('#username').val();
                                }
                            },
                            complete: function complete(returnedData) {
                                handleRemoteValidation('.reset-password-form', 'password', returnedData);
                            }
                        }
                    },
                    rpassword: {
                        equalTo: "#password"
                    }
                },
                messages: { // custom messages for radio buttons and checkboxes
                    password: {
                        required: textPasswordRequiredAlternative
                    },
                    rpassword: {
                        required: textPasswordRepeat,
                        equalTo: textPasswordNotEqual
                    }
                },
                invalidHandler: function invalidHandler(event, validator) {//display error alert on form submit
                    // display error alert on form submit
                    $('.alert-error, .reset-password-form').show();
                },
                highlight: function highlight(element) {
                    // hightlight error inputs
                    $(element).closest('.control-group').addClass('error'); // set error class to the control group
                },
                success: function success(label) {
                    label.closest('.control-group').removeClass('error');
                    label.remove();
                },
                submitHandler: function submitHandler() {
                    resetPasswordSubmit();
                }
            });

            /**
             * Validierung des Social-Registrierunsg-Prozesses
             */
            $('.social-register-form').validate({
                errorElement: 'label', // default input error message container
                errorClass: 'help-inline', // default input error message class
                ignore: "",
                onkeyup: false,
                rules: {
                    geb_dat: {
                        required: true
                    },
                    email: {
                        required: true,
                        email: true,
                        remote: {
                            global: false,
                            url: "evewa4ajax.php?file=checkEmailAjax.php",
                            data: {
                                email: function email() {
                                    return $('.social-register-form input[name="email"]').val();
                                }
                            },
                            complete: function complete(returnedData) {
                                handleRemoteValidation('.social-register-form', 'email', returnedData);
                            }
                        }
                    }
                },
                messages: { // custom messages for radio buttons and checkboxes
                    geb_dat: {
                        required: textBirthdayRequired
                    },
                    email: {
                        required: textEmailRequired,
                        email: textEmailInvalid
                    }
                },
                invalidHandler: function invalidHandler(event, validator) {//display error alert on form submit

                },
                highlight: function highlight(element) {
                    // hightlight error inputs
                    $(element).closest('.control-group').addClass('error'); // set error class to the control group
                },
                success: function success(label) {
                    label.closest('.control-group').removeClass('error');
                    label.remove();
                },
                errorPlacement: function errorPlacement(error, element) {
                    if (element.attr("name") == "tnc") {
                        // insert checkbox errors after the container
                        error.addClass('help-small no-left-padding').insertAfter($('#register_tnc_error'));
                    } else {
                        error.insertAfter(element); // default error placement.
                    }
                },
                submitHandler: function submitHandler(form) {
                    socialregistersubmit();
                }
            });

            var handleRemoteValidation = function handleRemoteValidation(jqFormSelector, element, returnedData) {
                var $validator = $(jqFormSelector).validate(),
                    responseObject;

                try {
                    responseObject = JSON.parse(returnedData.responseText);
                } catch (e) {
                    responseObject = {
                        STATUS: 'FAILED',
                        MESSAGE: textValidationFailed
                    };
                }

                if (responseObject.STATUS === 'FAILED') {
                    var errorObject = {};
                    errorObject[element] = responseObject.MESSAGE;

                    $validator.showErrors(errorObject);
                }
            };

            // Validierung auf deutsches Datum
            $.validator.addMethod("date", function (value, element) {
                return value.match(/^\d\d\.\d\d\.\d\d\d\d$/);
            }, textDateFormat);
        }
    };
}();

globalThis.Login = Login;